import * as React from 'react';
import { ItemIngestionType, useAllClientsQuery } from 'src/graphql/generated/operations';
import ClientList from './list';

const Page = function () {
  const { data, loading } = useAllClientsQuery({
    variables: {
      ingestion: ItemIngestionType.InHouse,
    },
  });

  return <ClientList loading={loading} clientsData={data} />;
};

export default Page;
