import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import { AllClientsQuery } from 'src/graphql/generated/operations';

interface Props {
  clientsData: AllClientsQuery;
  loading: boolean;
}

const LoadingRow = function () {
  return (
    <>
      <ListItem>
        <ListItemAvatar>
          <Skeleton variant="circular" width={40} height={40} />
        </ListItemAvatar>
        <ListItemText
          primary={<Skeleton variant="text" height={24} width={180} />}
          secondary={<Skeleton variant="text" height={24} width={120} />}
        />
      </ListItem>
      <Divider />
    </>
  );
};

const ClientList = function ({ clientsData, loading }: Props) {
  const clientsByIngestion = clientsData?.clients
    ? clientsData.clients
        .map((client) =>
          client.ingestions.map((ingestion) => ({
            id: client.id,
            logo: ingestion.logo,
            ingestion: ingestion.type,
            name: client.name,
            displayName: client.displayName,
          })),
        )
        .flat()
    : [];

  return (
    <List>
      <>
        {loading && (
          <>
            <LoadingRow />
            <LoadingRow />
            <LoadingRow />
            <LoadingRow />
            <LoadingRow />
            <LoadingRow />
            <LoadingRow />
            <LoadingRow />
          </>
        )}
        {clientsByIngestion.map((client) => (
          <React.Fragment key={`${client.id}-${client.ingestion}`}>
            <a
              aria-label={`client-${client.name}-${client.ingestion}`}
              href={`/${encodeURIComponent(client.name)}?takeaway=${
                client.ingestion === 'TAKEAWAY' ? 'true' : 'false'
              }`}
              style={{ textDecoration: 'none' }}
            >
              <ListItem
                button
                secondaryAction={
                  <IconButton edge="end">
                    <ChevronRightIcon />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar src={client.logo} />
                </ListItemAvatar>
                <ListItemText
                  primary={client.displayName}
                  secondary={<Chip component="span" label={client.ingestion} size="small" />}
                />
              </ListItem>
            </a>
            <Divider />
          </React.Fragment>
        ))}
      </>
    </List>
  );
};

export default ClientList;
